import { Component, OnInit } from '@angular/core';

declare function brython(): any;

@Component({
  selector: 'app-printwithvar',
  templateUrl: './printwithvar.component.html',
  styleUrls: ['./printwithvar.component.scss']
})
export class PrintwithvarComponent implements OnInit {

  constructor() {   }

  ngOnInit(): void {
    brython()
  }

  printWithOneVar:string = `x = 5
print(x)`;

  printString:string= `fruit = 'apples'
print('I like',fruit)`;

}
