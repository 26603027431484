import { Component, OnInit } from '@angular/core';
declare function brython(): any

@Component({
  selector: 'app-username-pwd-login',
  templateUrl: './username-pwd-login.component.html',
  styleUrls: ['./username-pwd-login.component.scss']
})
export class UsernamePwdLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }


program = `
userName = input('Enter user name ')
password = input('Enter password ')

if(userName == 'python' and password == 'awesome'):
  print('You are an authorized user')
else:
  print('You are an unauthorized user')
  `

}
