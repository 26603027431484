import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-swapvalues',
  templateUrl: './swapvalues.component.html',
  styleUrls: ['./swapvalues.component.scss']
})
export class SwapvaluesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }
  program = `# Swap two numbers

# Ask user to input two numbers.
firstNumber = float(input('Enter your first number'))
secondNumber = float(input('Enter your second number'))

print('Before swapping first number = ',firstNumber,'second number = ',secondNumber)

# Swap the numbers
temproary = secondNumber    # Temproraily copy the value.
secondNumber = firstNumber
firstNumber = temproary     # Copy the value back to first number.

print('After swapping first number = ',firstNumber,'second number = ',secondNumber)
`
}
