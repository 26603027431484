import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-vat-calculator',
  templateUrl: './vat-calculator.component.html',
  styleUrls: ['./vat-calculator.component.scss']
})
export class VatCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `
# VAT calculator
amount = float(input('Enter amount'))
VAT = 20/100
totalCost = amount + amount * VAT
print('Total cost to pay including VAT', totalCost)
`
}
