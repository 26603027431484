import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-studentdb',
  templateUrl: './studentdb.component.html',
  styleUrls: ['./studentdb.component.scss']
})
export class StudentdbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `
# Reading CSV content from a file
import csv

def read_file():
	with open('stduents.csv', newline='') as f:
		reader = csv.reader(f)
		for row in reader:
			print(row)

def write_file(name,address):
	# Writing CSV content to a file
	with open('stduents.csv', 'a', newline='') as f:
		writer = csv.writer(f)
		writer.writerow([name,address])

while(1):
	print('Menu')
	print('1. View student information')
	print('2. Record student information')
	print('3. Any other number to quit')

	item = int(input('Please select your option.'))

	if(item == 1):
		read_file()
	elif(item == 2):
		print('Enter student name as q to return to menu')
		while(1):
			name = input('Enter stduent name: ')
			if(name == 'q'):
				break
			address = input('Enter stduent address: ')
			write_file(name,address=address)
	else:
		exit()
`

}
