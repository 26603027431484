<app-programtitle title="Find largest of two integers"></app-programtitle>

<p>Write a program to find out the largest of given two integers.</p>

<p>Here, we use <b>int</b> function to convert user's input to an integer.</p>

<p>
<i> Python uses <b>indentation (whitespaces from the begining of the line)</b> to define a block of code</i>
</p>

<p>The if statement in Python checks if the given condition is true. If it is true then it executes code inside its block (identified by the indented code). Otherwise, executes the statements in the else block (optional).</p>

<app-editor code="{{program}}"> </app-editor>

<app-exercisetitle title="Exercise"> </app-exercisetitle>

<p>Write a program to find out the smallest of given two integers.</p>
