import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }
program = `
class cal():
  def __init__(self,a,b):
    self.a = a
    self.b = b

  def add(self):
    return self.a + self.b
  def sub(self):
    return self.a - self.b
  def multiply(self):
    return self.a * self.b
  def divide(self):
    return self.a / self.b

print("Select an operation.")
print("1.Add")
print("2.Subtract")
print("3.Multiply")
print("4.Divide")


while True:
    userSelection = input("Enter your choice: ")

    if userSelection in ('1', '2', '3', '4'):
        num1 = float(input("Enter your first number: "))
        num2 = float(input("Enter your second number: "))

        calc = cal(num1, num2)

        if userSelection == '1':
            print(num1, "+", num2, "=", calc.add())

        elif userSelection == '2':
            print(num1, "-", num2, "=", calc.sub())

        elif userSelection == '3':
            print(num1, "*", num2, "=", calc.multiply())

        elif userSelection == '4':
            print(num1, "/", num2, "=", calc.divide())
        break
    else:
        print("Invalid Input")
`
}
