import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-higestmarkscalculator',
  templateUrl: './higestmarkscalculator.component.html',
  styleUrls: ['./higestmarkscalculator.component.scss']
})
export class HigestmarkscalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `
list = []

n = int(input('How many subject marks are you going to enter? '))

for i in range(1, n+1):
    m = int(input('Enter marks in subject {i} '.format(i=i)))
    list.append(m)

print('Your marks are ', list)
print('You got the highest mark of ', max(list))

`

}
