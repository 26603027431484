
<app-programtitle title="Printing with expressions"></app-programtitle>

<p>Python displays everything given in single or double quotes. When something is not included in quotes, Python will try to interpret and derive its meaning. In the examples below, Python interprets as a mathematical expression and tries to evaluate it and displays the output.</p>

<app-editor code="{{displayNumberProgram}}"> </app-editor>

<h4>Python operator precedence (BODMAS)</h4>
<br/>
<p> When evaluating mathematical expressions Python follows an order of precedence for operators. Some of the commonly used operators and their precedence are given below</p>
<br/>

<li> B      - Brackets </li>
<li> O      - Others such as exponentiation operator **</li>
<li>D/M    - Dvision/Multiplication. Have same priority. Evaluated left-to-right</li>
<li>A/S    - Addition/subtraction. Evaluated left-to-right</li>
<br/>
<app-editor code="{{orderOfPrecendenceProgram}}"> </app-editor>