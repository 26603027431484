
<div>
<div class="jumbotron text-center">
    <h1>Python programming for beginners</h1>
    <p>Learn Python coding using interactive online practice lessons and exercises</p>
</div>
</div>

<div class="container-fluid">
    <div class="row">
    <div class="col-sm-8">
        <h2>Learn to code in Python</h2>
        <p>Hundreds of examples of python programs varying in size and complexity for you to explore and learn. You can run these programs and view the output instantly. The editor is a full-fledged python editor with Python keywords highlighed in color. You can write own programs and view the output. </p>
        <p>Practice these samples programs in order. The difficulty level increases gradually. Don't just run the code and move on. You should try changing the input and output for various scenarios you can think of. You might struggle initially but soon you will get the hang of it. Making mistakes and fixing it is the most important part in learning any programming languages.</p>
        <!-- <button class="btn btn-outline-primary">Show Topics</button> -->
    </div>
    <div class="col-sm-4">
        <!-- <span class="fa fa-align-left logo"></span> -->
        <a href="../../assets/ExplorePython.pdf" download>
          <img src="../../assets/downloadpdf.png" style="vertical-align:middle;margin:50px 0px 50px" width="75%" height="75%"  />
         </a>
    </div>
    </div>
</div>

<div class="container-fluid text-center">
    <h2>FEATURES</h2>
    <!-- <h4>What we offer</h4> -->
    <br>
    <div class="row">
      <div class="col-sm-4">
        <span class="fa fa-clipboard logo-small"></span>
        <h5>ONLINE IDE</h5>
        <p>A full-fledged Pyhon editor with syntax coloring, line numbers etc to make programming easier.</p>
      </div>
      <div class="col-sm-4">
        <span class="fa fa-play logo-small"></span>
        <h4>INTERPRETER</h4>
        <p>Python interpreter integrated ready to execute the awesome programs you develop</p>
      </div>
      <div class="col-sm-4">
        <span class="fa fa-flag-checkered logo-small"></span>
        <h5>INSTANT RESULTS</h5>
        <p>Every Python program can be executed instantly. No need to connect any server to execute the programs.</p>
      </div>
      </div>
      <br><br>
    <div class="row">
      <div class="col-sm-4">
        <span class="fa fa-flask logo-small"></span>
        <h5>SAMPLES</h5>
        <p>Hundreds of samples on every topic making it easy to learn and practice</p>
      </div>
      <div class="col-sm-4">
        <span class="fa fa-tasks logo-small"></span>
        <h5>EXERCISES</h5>
        <p>Lots of challenges and exercises with answers to test your knowledge</p>
      </div>
      <div class="col-sm-4">
        <span class="fa fa-question logo-small"></span>
        <h5>QUESTIONS</h5>
        <p>Lots of questions to refresh your memory and succeed in any tests or interviews</p>
      </div>
    </div>
  </div>

<br/>
<br/>
<!-- <h1 style="text-align: center;color:blue">Learn and practice Python programming with hundreds of sample programs</h1> -->
<br/>
<div>
<!-- <img src="./../../assets/woman.jpg" class="float-right" width="40%" style="margin-bottom: 5em;"> -->
</div>


<!-- <h2>Practice programming in Python </h2>
<br/>
<br/>

<p style="font-size: 2.0em;color: #000000;">Here, you will find hundreds of examples of python programs varying in size and complexity. You can run these programs immedietly and view the output. The editor is a full-fledged python editor. You can write own programs and view the output instantly.</p>
<p style="font-size: 2.0em;color: #000000;">Practice the programs in the order. The difficulty level increases gradually.</p>
<p style="font-size: 2.0em;"><span style="color: #ff6600;"><strong></strong></span></p>
<p style="font-size: 2.0em;"><span style="color: #000000;">It is important that you don't just run the code, view the output and move on. You should try changing the input and output for various scenarios you can think of. You might struggle initially but soon you will get the hang of it. Making mistakes and learning to correct it is the most important part in learning any programming languages.&nbsp;</span></p>
<p style="font-size: 2.0em;">&nbsp;</p>
<p style="font-size: 2.0em;"><span style="color: #000000;">Good luck!!!</span></p> -->
