import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-dice-throw',
  templateUrl: './dice-throw.component.html',
  styleUrls: ['./dice-throw.component.scss']
})
export class DiceThrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `
from random import randrange

a = [0] * 6

for i in range(0,1000):
	t = randrange(0,6)
	a[t] = a[t]+1

print(a)

`

}
