<app-programtitle title="Display all the numbers from 1 to 100"></app-programtitle>

<p> Write a program to display all the numbers up to 100 </p>
<br/>
<app-editor code="{{display1to100}}"> </app-editor>

<p> Combining for and input statements </p>

<br/>
<app-editor code="{{display1toUserEnteredNumber}}"> </app-editor>

<p>  Write a program to display all the odd numbers from 1 to 100 </p>
<br/>

<app-editor code="{{displayOddNumbers}}"> </app-editor>

<p>Challenge: Write a program to display all the even numbers from 1 to 100 " </p>

<button class="btn btn-outline-primary" (click) = "toggleEvenProgram()" id ="NoBrython">Show answer</button>
<br/>
<br/>
<app-editor *ngIf = "!isEvenProgramHidden" code="{{displayEvenNumbers}}"> </app-editor>


<p> Challenge: Write a program to display all the even numbers from 0 up to a given number "</p>
<button class="btn btn-outline-primary" (click) = "toggleUserEnteredEvenProgram()" id ="NoBrython">Show answer</button>
<br/>
<br/>
<app-editor *ngIf = "!isUserEnteredEvenProgramHidden" code="{{displayEvenNumbersUptoUserEntered}}"> </app-editor>