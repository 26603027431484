<app-programtitle title="Think of a number"></app-programtitle>

<p> Here, we will see a series of fun Maths tricks to amuse your friends. </p>

<p> The important thing to learn here is the use of print and the input functions. </p>

<p>The input function waits for the user's inputs. </p>

<p> Run the below programs and ask your friends to follow the instructions. Take your time (and say some magic words) before answering.</p>

<p> </p>

<app-editor code="{{program1}}"> </app-editor>

<p> Here is another trick. The answer will always be 10 </p>
<app-editor code="{{program2}}"> </app-editor>

<p> Here is another one. The answer will always be 3 </p>
<app-editor code="{{program3}}"> </app-editor>

