import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-alphabet',
  templateUrl: './alphabet.component.html',
  styleUrls: ['./alphabet.component.scss']
})
export class AlphabetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }
program = `# Find whether a given character is an alphabet or not
char = input("Enter a character: ")
if((char >='a' and char <= 'z') or (char >='A' and char <='Z')):
  print(char, "is an Alphabet")
else:
  print(char, "is not an Alphabet")
`
}
