import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-positivenegative',
  templateUrl: './positivenegative.component.html',
  styleUrls: ['./positivenegative.component.scss']
})
export class PositivenegativeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `# Find whether a given number is positive, negative or zero

userNumber = int(input('Enter your number'))

if(userNumber > 0):
    print(userNumber,'is a positive integer')
elif(userNumber < 0):
    print(userNumber,'is a negative integer')
else:
    print('You have entered zero')
  `

}
