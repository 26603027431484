import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-matix-addition',
  templateUrl: './matix-addition.component.html',
  styleUrls: ['./matix-addition.component.scss']
})
export class MatixAdditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `
# Matrix 1
rows1 = int(input('How many rows in first matrix? '))
cols1 = int(input('How many columns in first matrix? '))

m1 = [[0 for j in range(cols1)] for i in range(rows1)]

print('Enter values for the first matrix')

for r in range(rows1):
    for c in range(cols1):
     m1[r][c] = int(input('Enter a value for {r}, {c} '.format(r=r+1,c=c+1)))

# Matrix 2
rows2 = int(input('How many rows in second matrix? '))
cols2 = int(input('How many columnss in second matrix? '))

m2 = [[0 for j in range(cols2)] for i in range(rows2)]

print('Enter values for the second matrix')

for r in range(rows2):
    for c in range(cols2):
     m2[r][c] = int(input('Enter a value for {r}, {c} '.format(r=r+1,c=c+1)))

val = 0
for r1 in range(rows1):
    for c2 in range(cols2):
            for r2 in range(rows2):
                val = val + m1[r1][r2] * m2[r2][c2]
            print(val,'  ', end="")
            val = 0
    print()
  `

}
