<form>
<div ace-editor 
       [(text)]="code" 
       [mode]="'python'"
       [theme]="'twilight'"
       [options]="options"
       [readOnly]="false"
       [autoUpdateContent]="true" 
       [durationBeforeCallback]="1000" 
       (textChanged)="onChange($event)"
       style="min-height: 100px; width:100%; overflow: auto;background-color: whitesmoke;border-color: blue;"
       id="{{aceditorid}}"
    >
</div> 

<br/>
<button type="button" class="btn btn-outline-primary" id={{id}} (click)="RunbuttonClicked()">
<!-- <fa-icon [icon]="faRunning"></fa-icon> -->

Run
</button>
<br/>
<br/>
<p><textarea id="{{txtAreaId}}" [hidden]="isOutputEditorHidden" style="min-height: 100px; width:100%; overflow: auto;color:black;background-color:white;font-size: 0.8em;border-color: blue;"></textarea></p>

</form>