import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarModule } from 'ng-sidebar';
import { HelloworldComponent } from './print/helloworld/helloworld.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AceEditorModule } from 'ngx-ace-editor-wrapper';
import { EditorComponent } from './editor/editor.component';
import { PrintwithvarComponent } from './print/printwithvar/printwithvar.component';
import { Display1to100Component } from './forloops/display1to100/display1to100.component';
import { SumdigitsComponent } from './forloops/sumdigits/sumdigits.component';
import { SwapvaluesComponent } from './input/swapvalues/swapvalues.component';
import { CircumcricleComponent } from './input/circumcricle/circumcricle.component';
import { CalcgradeComponent } from './ifelse/calcgrade/calcgrade.component';
import { LargestComponent } from './ifelse/largest/largest.component';
import { OddorevenComponent } from './ifelse/oddoreven/oddoreven.component';
import { PositivenegativeComponent } from './ifelse/positivenegative/positivenegative.component';
import { AlphabetComponent } from './ifelse/alphabet/alphabet.component';
import { FilecopyComponent } from './file/filecopy/filecopy.component';
import { FreqwordsComponent } from './setdict/freqwords/freqwords.component';
import { NumberoflinesComponent } from './file/numberoflines/numberoflines.component';
import { CountwordsComponent } from './file/countwords/countwords.component';
import { SumofnumbersComponent } from './function/sumofnumbers/sumofnumbers.component';
import { SumofnumbersusingclassComponent } from './class/sumofnumbersusingclass/sumofnumbersusingclass.component';
import { VowelsComponent } from './ifelse/vowels/vowels.component';
import { AverageComponent } from './function/average/average.component';
import { CalculatorComponent } from './class/calculator/calculator.component';
import { ProgramtitleComponent } from './programtitle/programtitle.component';
import { ExercisetitleComponent } from './exercisetitle/exercisetitle.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ArraysComponent } from './arrays/arrays.component';
import { ThinkOfANumberComponent } from './input/think-of-a-number/think-of-a-number.component';
import { MultiplicationTableComponent } from './forloops/multiplication-table/multiplication-table.component';
import { BubbleSortComponent } from './forloops/bubble-sort/bubble-sort.component';
import { EvaluateExpressionComponent } from './print/evaluate-expression/evaluate-expression.component';
import { IndexComponent } from './index/index.component';
import { FAQComponent } from './faq/faq.component';
import { FahrenheitToCelsiusComponent } from './input/fahrenheit-to-celsius/fahrenheit-to-celsius.component';
import { CompundInterestComponent } from './input/compund-interest/compund-interest.component';
import { PercentIncreaseComponent } from './input/percent-increase/percent-increase.component';
import { LeapYearComponent } from './ifelse/leap-year/leap-year.component';
import { FactorialComponent } from './forloops/factorial/factorial.component';
import { UsernamePwdLoginComponent } from './ifelse/username-pwd-login/username-pwd-login.component';
import { DecimalToBinaryComponent } from './ifelse/decimal-to-binary/decimal-to-binary.component';
import { SumOfSeriesComponent } from './forloops/sum-of-series/sum-of-series.component';
import { DayOfWeekComponent } from './ifelse/day-of-week/day-of-week.component';
import { EnergyBillCalculatorComponent } from './ifelse/energy-bill-calculator/energy-bill-calculator.component';
import { MatrixDisplayComponent } from './arrays/matrix-display/matrix-display.component';
import { MatixAdditionComponent } from './arrays/matix-addition/matix-addition.component';
import { VatCalculatorComponent } from './input/vat-calculator/vat-calculator.component';
import { BonusCalculatorComponent } from './class/bonus-calculator/bonus-calculator.component';
import { PalindromeComponent } from './arrays/palindrome/palindrome.component';
import { HigestmarkscalculatorComponent } from './arrays/higestmarkscalculator/higestmarkscalculator.component';
import { BudgetcalculatorComponent } from './arrays/budgetcalculator/budgetcalculator.component';
import { BillingComponent } from './class/billing/billing.component';
import { StudentdbComponent } from './file/studentdb/studentdb.component';
import { StudentdiscountComponent } from './class/studentdiscount/studentdiscount.component';
import { BinarysearchComponent } from './function/binarysearch/binarysearch.component';
import { MergesortComponent } from './function/mergesort/mergesort.component';
import { MatrixMultiplicationComponent } from './arrays/matrix-multiplication/matrix-multiplication.component';
import { TaxiFareCalculatorComponent } from './function/taxi-fare-calculator/taxi-fare-calculator.component';
import { TimeInSecondsComponent } from './function/time-in-seconds/time-in-seconds.component';
import { LicenseEligibilityComponent } from './function/license-eligibility/license-eligibility.component';
import { DiceThrowComponent } from './arrays/dice-throw/dice-throw.component';

@NgModule({
  declarations: [
    AppComponent,
    HelloworldComponent,
    HomepageComponent,
    EditorComponent,
    PrintwithvarComponent,
    Display1to100Component,
    SumdigitsComponent,
    SwapvaluesComponent,
    CircumcricleComponent,
    CalcgradeComponent,
    LargestComponent,
    OddorevenComponent,
    PositivenegativeComponent,
    AlphabetComponent,
    FilecopyComponent,
    FreqwordsComponent,
    NumberoflinesComponent,
    CountwordsComponent,
    SumofnumbersComponent,
    SumofnumbersusingclassComponent,
    VowelsComponent,
    AverageComponent,
    CalculatorComponent,
    ProgramtitleComponent,
    ExercisetitleComponent,
    ArraysComponent,
    ThinkOfANumberComponent,
    MultiplicationTableComponent,
    BubbleSortComponent,
    EvaluateExpressionComponent,
    IndexComponent,
    FAQComponent,
    FahrenheitToCelsiusComponent,
    CompundInterestComponent,
    PercentIncreaseComponent,
    LeapYearComponent,
    FactorialComponent,
    UsernamePwdLoginComponent,
    DecimalToBinaryComponent,
    SumOfSeriesComponent,
    DayOfWeekComponent,
    EnergyBillCalculatorComponent,
    MatrixDisplayComponent,
    MatixAdditionComponent,
    VatCalculatorComponent,
    BonusCalculatorComponent,
    PalindromeComponent,
    HigestmarkscalculatorComponent,
    BudgetcalculatorComponent,
    BillingComponent,
    StudentdbComponent,
    StudentdiscountComponent,
    BinarysearchComponent,
    MergesortComponent,
    MatrixMultiplicationComponent,
    TaxiFareCalculatorComponent,
    TimeInSecondsComponent,
    LicenseEligibilityComponent,
    DiceThrowComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SidebarModule.forRoot(),
    AceEditorModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
