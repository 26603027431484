import{Router, NavigationEnd} from '@angular/router';
import { Component } from '@angular/core';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faAddressBook} from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  _opened: boolean = false;
  title = 'Python';
  faPrint = faPrint;
  faKeyboard = faKeyboard;
  faCodeBranch = faCodeBranch;
  faRedoAlt = faRedoAlt;
  faHome = faHome;
  faBars = faBars;
  faQuestionCircle = faQuestionCircle;
  faAddressBook = faAddressBook;

  constructor(public router: Router){   
    this.router.events.subscribe(event => {
       if(event instanceof NavigationEnd){
           gtag('config', 'G-HL6D4MHBP8', 
                 {
                   'page_path': event.urlAfterRedirects
                 });
        }
     }
  )}
  
  public _toggleSidebar() 
  {
    this._opened = !this._opened;
  }
 
}
