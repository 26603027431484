import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-taxi-fare-calculator',
  templateUrl: './taxi-fare-calculator.component.html',
  styleUrls: ['./taxi-fare-calculator.component.scss']
})
export class TaxiFareCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `
def calculate_fare(passengers, miles):
  if miles == 0:
    return 0
    
  cost = (miles - 1) * 2 + 3
  if passengers >= 5:
    cost = cost + cost * 0.25
  return cost

passengers = int(input('Enter number of passengers: '))
miles = int(input('Enter miles travelled: '))
cost = calculate_fare(passengers,miles)
print('Total cost = ', cost)

  `

}
