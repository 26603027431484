<app-programtitle title="Calcualate percentage increase/decrease"></app-programtitle>

<p> Write a program to calculate percentage increase. </p>
<p> Use the formula to calculate ((Final Value - Initial Value)/ Initial Value) * 100</p>

<app-editor code="{{program}}"> </app-editor>

<app-exercisetitle title="Exercise"> </app-exercisetitle>
<p> Write a program to calculate percentage decrease. </p>
<p> Use the formula to calculate ((Initial Value - Final Value)/ Final Value) * 100</p>

<button class="btn btn-outline-primary" (click) = "toggleProgram()" id ="NoBrython">Show answer</button>
<br/>
<br/>
<app-editor *ngIf = "!isHidden" code="{{exerciseProgram}}"> </app-editor>
