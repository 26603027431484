
<app-programtitle title="Find the sum of the digits in a given number"></app-programtitle>
<p>
For example,if user enters a number say 235, then the output will be 2+3+5, that is 10.
</p>
<app-editor code="{{program}}"> </app-editor>


<h5 style="color:brown;" >Explanation</h5>    
<p>
The modulus operator (%) returns the reminder. The division operator (/) returns the quotient. 

We use the modulus operator to get the last digit of a number. When a number is divided by 10, the last digit will usually be the reminder. 

To get to the next digit, we first divide it by 10 and the quotient. The quotient will be the number except the last digit.

We repeat the process until all the digits are summed up. 

For E.g,

Lets say the user enter 1234,

After entering the while loop,

</p>

<table style="border-collapse: collapse; width: 100%; height: 90px;" border="1">
    <tbody>
    <tr style="height: 18px; background-color: #3366ff;">
    <td style="width: 33.3333%; height: 18px; text-align: center;"><span style="color: #ffffff; ">User Entered Number</span></td>
    <td style="width: 33.3333%; height: 18px; text-align: center;"><span style="color: #ffffff;">Reminder</span></td>
    <td style="width: 33.3333%; height: 18px; text-align: center;"><span style="color: #ffffff;">Sum of digits</span></td>
    </tr>
    <tr style="height: 18px;">
    <td style="width: 33.3333%; height: 18px; text-align: center;">1234</td>
    <td style="width: 33.3333%; height: 18px; text-align: center;">4</td>
    <td style="width: 33.3333%; height: 18px; text-align: center;">4</td>
    </tr>
    <tr style="height: 18px;">
    <td style="width: 33.3333%; height: 18px; text-align: center;">123</td>
    <td style="width: 33.3333%; height: 18px; text-align: center;">3</td>
    <td style="width: 33.3333%; height: 18px; text-align: center;">7</td>
    </tr>
    <tr style="height: 18px;">
    <td style="width: 33.3333%; height: 18px; text-align: center;">12</td>
    <td style="width: 33.3333%; height: 18px; text-align: center;">2</td>
    <td style="width: 33.3333%; height: 18px; text-align: center;">9</td>
    </tr>
    <tr style="height: 18px;">
    <td style="width: 33.3333%; text-align: center; height: 18px;">1</td>
    <td style="width: 33.3333%; text-align: center; height: 18px;">1</td>
    <td style="width: 33.3333%; text-align: center; height: 18px;">10</td>
    </tr>
    </tbody>
    </table>

