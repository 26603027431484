import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-freqwords',
  templateUrl: './freqwords.component.html',
  styleUrls: ['./freqwords.component.scss']
})
export class FreqwordsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }
program = `
# Count the frequency of words in a given sentence

sentence = input("Enter a setence:")
listOfWords = sentence.split() # Split and collect the words 
wordfrequency = [listOfWords.count(word) for word in listOfWords] # Count the words 
print(dict(zip(listOfWords,wordfrequency)))
`
}
