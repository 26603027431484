import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-energy-bill-calculator',
  templateUrl: './energy-bill-calculator.component.html',
  styleUrls: ['./energy-bill-calculator.component.scss']
})
export class EnergyBillCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `# Electricity bill calculator
prevReading = int(input('Enter previous reading'))
currReading = int(input('Enter current reading'))

if(prevReading > currReading):
  print('Wrong inputs. Previous reading can not be smallar than current reading')
else:
  pricePerUnit = 10.5
  totalCost = (currReading - prevReading) * pricePerUnit
  print('Total cost to pay', totalCost)

    `
}
