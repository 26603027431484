import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-bubble-sort',
  templateUrl: './bubble-sort.component.html',
  styleUrls: ['./bubble-sort.component.scss']
})
export class BubbleSortComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program =  `# Bubble sort
# Remove all the print statements (except the last one) for a cleaner output

totalNumbers = int(input('How many numbers are you going to enter?'))
numbers = [] # Store all the user entered numbers in an array
total = 0
  
for i in range(0,totalNumbers):   
  number = int(input('Enter number ' + str(i+1)))
  numbers.append(number)
  
print('The numbers before sorting are', numbers)

# Uncomment the print statement to see how sorting is performed.
for i in range(totalNumbers - 1):  
    # print('Starting pass', i + 1)
    
    for j in range(totalNumbers - i - 1):
        # print('Comparing',numbers[j],'and',numbers[j+1], 'and swap their position if required')
        if(numbers[j] > numbers [j + 1]):
            temp = numbers[j]
            numbers[j]  = numbers[j + 1]
            numbers[j + 1] = temp
        # print(numbers)
            
    # print('After pass',i + 1, 'the largest number is pushed to position', totalNumbers - i)
    # print(numbers)
    
print ('The sorted numbers are ',numbers)`;

}
