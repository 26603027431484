
<!-- https://bootstrapious.com/p/bootstrap-sidebar -->
<div>
 <div class="wrapper">
  <!-- Sidebar -->
  <nav id="sidebar" [class.active]="_opened" >

    <div id="dismiss">
        <i (click)="_toggleSidebar()" class="fa fa-arrow-circle-left" style="font-size:24px;color:white"></i>
    </div> 

      <div class="sidebar-header">
        <img src="assets/pythonlogo.png" />
      </div>

      <ul class="list-unstyled components">
        <li class="active">
           
            <a href="#print" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <!-- <fa-icon [icon]="faPrint" style="margin:5px"></fa-icon> -->
                Print
            </a>
            <ul class="collapse show list-unstyled" id="print">
                <li>
                    <a routerLink="/hello-world" routerLinkActive="active" >Hello world!</a>
                </li>
                <li>
                    <a routerLink="/evaluate-expression" routerLinkActive="active"   >Print with expressions</a>
                </li>
                <li>
                    <a routerLink="/print-var" routerLinkActive="active"   >Print with variables</a>
                </li>
            </ul>
        </li>
        <li class="active">
            <a href="#input" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <!-- <fa-icon [icon]="faKeyboard" style="margin:5px"></fa-icon> -->
                Input
            </a>
            <ul class="collapse show list-unstyled" id="input">
                <li>
                    <a routerLink="/think-of-a-number" routerLinkActive="active">Think of a number</a>
                </li>
                <li>
                    <a routerLink="/area-of-circle" routerLinkActive="active">Calculate area of a cirlce</a>
                </li>
                <li>
                    <a routerLink="/fahren-to-celsius" routerLinkActive="active">Fahrenheit to celisus</a>
                </li>
                <li>
                    <a routerLink="/compound-interest" routerLinkActive="active">Compound interest</a>
                </li>
                <li>
                    <a routerLink="/vat-calculator" routerLinkActive="active">VAT calculator</a>
                </li>
                <li>
                    <a routerLink="/percent-increase" routerLinkActive="active">Percentage increase</a>
                </li>
                <li>
                    <a routerLink="/swap-values" routerLinkActive="active">Swap two numbers</a>
                </li>
             <!--
                <li>
                    <a href="#">Calculate square root</a>
                </li>
                <li>
                    <a href="#">Calculate average of two numbers</a>
                </li>
                <li>
                    <a href="#">Calculate average of two numbers</a>
                </li> -->
            </ul>
        </li>

          <li class="active">
            <a href="#ifelse" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <!-- <fa-icon [icon]="faCodeBranch" style="margin:5px"></fa-icon> -->
                If..Else
            </a>
            <ul class="collapse show list-unstyled" id="ifelse">
                <li>
                    <a routerLink="/find-largest" routerLinkActive="active"   >Find largest of two numbers</a>
                </li>
                <li>
                    <a routerLink="/odd-or-even" routerLinkActive="active"   >Find odd or even number</a>
                </li>
                <li>
                    <a routerLink="/positive-negative-zero" routerLinkActive="active"   >Check if a number is +ve or -ve or zero</a>
                </li>
                <li>
                    <a routerLink="/energy-bill" routerLinkActive="active"   >Energy bill calculator</a>
                </li>
                <li>
                    <a routerLink="/leap-year" routerLinkActive="active"   >Leap year</a>
                </li>
                <li>
                    <a routerLink="/day-of-week" routerLinkActive="active"   >Day of week</a>
                </li>
                <li>
                    <a routerLink="/calculate-grade" routerLinkActive="active"   >Calculate grade</a>
                </li>
                <li>
                    <a routerLink="/find-alphabet" routerLinkActive="active"   >Find a characracter alphabet</a>
                </li>
                <li>
                    <a routerLink="/username-pwd" routerLinkActive="active"   >Login user name/password</a>
                </li>
                <li>
                    <a routerLink="/find-vowel" routerLinkActive="active"   >Find if a characracter is vowel</a>
                </li>
            </ul>
        </li>
          <li class="active">
              <a href="#forwhile" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <!-- <fa-icon [icon]="faRedoAlt" style="margin:5px"></fa-icon> -->
                  For and while loops
                </a>
              <ul class="collapse show list-unstyled" id="forwhile">
                  <li>
                      <a routerLink="/print-1-to-100" routerLinkActive="active"   >Display from 1 to 100</a>
                  </li>
                  <li>
                    <a routerLink="/decimal-binary" routerLinkActive="active"   >Deicmal to binary</a>
                  </li>
                  <li>
                    <a routerLink="/multiplication-table" routerLinkActive="active"  > Multiplication table </a>
                  </li>
                  <!-- <li>
                      <a href="#">Find the factors of a number</a>
                  </li>
                  <li>
                      <a href="#">Display from 1 to 100 all even numbers only</a>
                  </li> -->
                  <li>
                    <a routerLink="/sum-of-digits" routerLinkActive="active"   >Find sum of digits</a>
                  </li>
                  <li>
                    <a routerLink="/bubble-sort" routerLinkActive="active"   >Bubble sort</a>
                </li>
              </ul>
          </li>

          <li class="active">
            <a href="#arrays" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">Arrays</a>
            <ul class="collapse show list-unstyled" id="arrays">
                <li>
                    <a routerLink="/dice-throw" routerLinkActive="active">Rolling a dice</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="arrays">
                <li>
                    <a routerLink="/total-of-N-numbers" routerLinkActive="active">Find total of n numbers</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/highest-marks" routerLinkActive="active">Highest mark</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/budget-calculator" routerLinkActive="active">Budget Calculator</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/matrix-addition" routerLinkActive="active">Matrix addition</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/matrix-multiplication" routerLinkActive="active">Matrix multiplication</a>
                </li>
            </ul>
         </li>
         <li class="active">
            <a href="#sets" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">Sets and Dictinoaries</a>
            <ul class="collapse show list-unstyled" id="sets">
                <li>
                    <a routerLink="/count-frequency-words" routerLinkActive="active">Count frequency of words</a>
                </li>
            </ul>
         </li>

         <li class="active">
            <a href="#file" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">File Handling</a>
            <ul class="collapse show list-unstyled" id="file">
                <li>
                    <a routerLink="/file-copy" routerLinkActive="active">Copy a file</a>
                </li>
                <li>
                    <a routerLink="/count-number-of-lines" routerLinkActive="active"   >Count number of lines</a>
                </li>
                <li>
                    <a routerLink="/count-number-of-words" routerLinkActive="active"   >Count number of words</a>
                </li>
                <li>
                    <a routerLink="/studentdb" routerLinkActive="active">Student DB</a>
                </li>
            </ul>
         </li>

              
         <li class="active">
            <a href="#functions" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">Functions and Lambdas</a>
            <ul class="collapse show list-unstyled" id="functions">
                <li>
                    <a routerLink="/license-eligibility" routerLinkActive="active">Driving license eligiblity</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="functions">
                <li>
                    <a routerLink="/taxi-fare-calculator" routerLinkActive="active">Taxi fare calculator</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="functions">
                <li>
                    <a routerLink="/time-in-seconds" routerLinkActive="active">Time in seconds</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="functions">
                <li>
                    <a routerLink="/average-of-n-numbers" routerLinkActive="active">Average of n numbers</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/palindrome" routerLinkActive="active">Palindrome</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/binarysearch" routerLinkActive="active">Binary Search</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/mergesort" routerLinkActive="active">Merge sort</a>
                </li>
            </ul>
         </li>

         <li class="active">
            <a href="#classes" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">Classes and Objects</a>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/calculator" routerLinkActive="active">Simple calculator</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/bonus-calculator" routerLinkActive="active">Bonus calculator</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/discountcalculator" routerLinkActive="active">Discount calculator</a>
                </li>
            </ul>
            <ul class="collapse show list-unstyled" id="classes">
                <li>
                    <a routerLink="/billing" routerLinkActive="active">Billing app</a>
                </li>
            </ul>
         </li>
       
         <!-- <li class="active">
            <a href="#inheritance" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Inheritence</a>
            <ul class="collapse list-unstyled" id="inheritance">
                <li>
                    <a href="#">Display from 1 to 100</a>
                </li>
         </li> -->

       <!--  <li class="active">
            <a href="#classes" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Modules</a>
            <ul class="collapse list-unstyled" id="classes">
                <li>
                    <a href="#">Display from 1 to 100</a>
                </li>
                <li>
                    <a href="#">Display from 1 to 100 all odd numbers only</a>
                </li>
                <li>
                    <a href="#">Display from 1 to 100 all even numbers only</a>
                </li>
            </ul>
         </li>-->

         <!-- <li class="active">
            <a href="#exception" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Exception handling</a>
            <ul class="collapse list-unstyled" id="exception">
                <li>
                    <a href="#">Display from 1 to 100</a>
                </li>
            </ul>
         </li> -->

       <!--  <li class="active">
            <a href="#inheritance" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Matplotlib</a>
            <ul class="collapse list-unstyled" id="inheritance">
                <li>
                    <a href="#">Display from 1 to 100</a>
                </li>
                <li>
                    <a href="#">Display from 1 to 100 all odd numbers only</a>
                </li>
                <li>
                    <a href="#">Display from 1 to 100 all even numbers only</a>
                </li>
            </ul>
         </li>

         <li class="active">
            <a href="#modules" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Numpy</a>
            <ul class="collapse list-unstyled" id="modules">
                <li>
                    <a href="#">Display from 1 to 100</a>
                </li>
                <li>
                    <a href="#">Display from 1 to 100 all odd numbers only</a>
                </li>
                <li>
                    <a href="#">Display from 1 to 100 all even numbers only</a>
                </li>
            </ul>
         </li>

          <li>
              <a href="#">Contact</a>
          </li> -->
      </ul>
  </nav>
  <div id="content" [class.active]="_opened" >
    <nav class="navbar navbar-expand-lg">
            <ul class="nav navbar-nav mr-auto">
                <button (click)="_toggleSidebar()" type="button" id="sidebarCollapse" class="btn bg-transparent text-light">
                    <fa-icon [icon]="faBars" style="margin:5px"></fa-icon>
                </button>
            </ul>
            <ul class="nav navbar-nav ml-auto">
                 <li class="nav-item active ">
                    <a class="nav-link" routerLink="/faq">
                        <fa-icon [icon]="faQuestionCircle" style="margin:5px"></fa-icon>
                        Python FAQ
                    </a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" routerLink="/index">
                        <fa-icon [icon]="faAddressBook" style="margin:5px"></fa-icon>
                        Exercises
                    </a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" href="#">
                        <fa-icon [icon]="faHome" style="margin:5px"></fa-icon>
                        Home
                    </a>
                </li>
            </ul>
    </nav>
    <div>
        <router-outlet></router-outlet>
    </div>
</div> 
</div>
</div>
