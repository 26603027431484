import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-day-of-week',
  templateUrl: './day-of-week.component.html',
  styleUrls: ['./day-of-week.component.scss']
})
export class DayOfWeekComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `# Display the day of the week

day = int(input("Enter a number between 1 and 7: "))

if(day < 1 or day > 7):
  print(day, "is not within the range of 1 and 7")
elif(day == 1):
  print("Sunday")
elif(day == 2):
  print("Monday")
elif(day == 3):
  print("Tuesday")
elif(day == 4):
  print("Wednesday")
elif(day == 5):
  print("Thursday")
elif(day == 6):
  print("Friday")
else:
  print("Saturday")
  `

}
