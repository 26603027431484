import { Component, OnInit } from '@angular/core';

declare function brython(): any;

@Component({
  selector: 'app-sumdigits',
  templateUrl: './sumdigits.component.html',
  styleUrls: ['./sumdigits.component.scss']
})
export class SumdigitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `# Add Digits of a Number using while Loop
userEnteredNumber = int(input("Enter a Number"))
sumOfDigits = 0

while userEnteredNumber>0:
  reminder = userEnteredNumber % 10   # Use modulus operator to get the last digit (reminder) by dividing it by 10
  sumOfDigits = sumOfDigits + reminder
  userEnteredNumber = int(userEnteredNumber/10) # Get all but the last digit by dividing it by 10 and get the quotient

print("Sum of the digits of the given number: ", sumOfDigits)`

}
