

<p>&nbsp;</p>
<h1 class="bg-white text-dark">Hello world!</h1>
<br/>
<p>It is traditional to start learning any language with a simple program that says hello to the world.&nbsp;</p>
<p>As a python programmer, you would want to display information, results of calculations or instructions etc. to the user. The python print function <i>(more on functions later)</i> displays anything 
    that is given within the quotes.
</p>
<p>Python understands only the print function. Anything given in the quote is meaningless to python. It is for humans to understand and interpret. Python merely echoes back 
    whatever is given within the quotes.
</p>
<app-editor code="{{helloWorldProgram}}"> </app-editor>

<app-exercisetitle title = "Exercise: Try changing the Hello world message to display hello and your name."></app-exercisetitle>
<p>You can display anything by including it within either a single quote (') or double quotes ("). A python program can have any number of print functions.</p>

<app-editor code="{{quotesProgram}}"> </app-editor>

<p>You can display a list by separting them by a comma</p>
<app-editor code="{{listProgram}}"> </app-editor>


