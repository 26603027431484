import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-budgetcalculator',
  templateUrl: './budgetcalculator.component.html',
  styleUrls: ['./budgetcalculator.component.scss']
})
export class BudgetcalculatorComponent implements OnInit {

  constructor() { 
   
  }

  ngOnInit(): void {
    brython()
  }

 
  program = `
# Budget calculator
income = int(input("Please enter your monthly income "))

expenses = []

totalExpenses = expense = 0

while(expense != -1):
    expense = int(input("Please enter an expense? Type -1 to stop entering "))
    if(expense == -1):
        break
    expenses.append(expense)
    totalExpenses = totalExpenses + expense

print("Your total income is ",income)

print("Your expenses are ")
for e in expenses:
    print(e)

print("Total expenses is ",totalExpenses)

if income > totalExpenses:
    print("You have surplus income of ", income - totalExpenses)
elif income < totalExpenses:
    print("You will have to borrow ", totalExpenses - income, 'to make ends meet.')
else:
    print("Your income and expenses are equal. Try saving a bit.")

  `
}
