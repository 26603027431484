import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomepageComponent } from './homepage/homepage.component'

import { HelloworldComponent } from './print/helloworld/helloworld.component'
import { PrintwithvarComponent } from './print/printwithvar/printwithvar.component'

import { MultiplicationTableComponent } from './forloops/multiplication-table/multiplication-table.component'
import { Display1to100Component } from './forloops/display1to100/display1to100.component'
import { SumdigitsComponent} from './forloops/sumdigits/sumdigits.component'
import { BubbleSortComponent} from './forloops/bubble-sort/bubble-sort.component'

import { ThinkOfANumberComponent} from './input/think-of-a-number/think-of-a-number.component'
import { SwapvaluesComponent} from './input/swapvalues/swapvalues.component'
import { CircumcricleComponent } from './input/circumcricle/circumcricle.component'
import { FahrenheitToCelsiusComponent } from './input/fahrenheit-to-celsius/fahrenheit-to-celsius.component';
import { CompundInterestComponent } from './input/compund-interest/compund-interest.component';
import { PercentIncreaseComponent } from './input/percent-increase/percent-increase.component';
import { VatCalculatorComponent } from './input/vat-calculator/vat-calculator.component';

import { CalcgradeComponent} from './ifelse/calcgrade/calcgrade.component'
import { DayOfWeekComponent } from './ifelse/day-of-week/day-of-week.component';
import { DecimalToBinaryComponent } from './ifelse/decimal-to-binary/decimal-to-binary.component';
import { EnergyBillCalculatorComponent } from './ifelse/energy-bill-calculator/energy-bill-calculator.component';
import { LeapYearComponent } from './ifelse/leap-year/leap-year.component';
import { UsernamePwdLoginComponent } from './ifelse/username-pwd-login/username-pwd-login.component';
import { LargestComponent } from './ifelse/largest/largest.component'
import { AlphabetComponent } from './ifelse/alphabet/alphabet.component'
import { OddorevenComponent } from './ifelse/oddoreven/oddoreven.component'
import { PositivenegativeComponent } from './ifelse/positivenegative/positivenegative.component'
import { VowelsComponent } from './ifelse/vowels/vowels.component'

import { SumofnumbersComponent } from './function/sumofnumbers/sumofnumbers.component'
import { AverageComponent } from './function/average/average.component'

import { SumofnumbersusingclassComponent } from './class/sumofnumbersusingclass/sumofnumbersusingclass.component'

import { ArraysComponent } from './arrays/arrays.component'

import { NumberoflinesComponent } from './file/numberoflines/numberoflines.component'
import { CountwordsComponent } from './file/countwords/countwords.component'
import { FilecopyComponent } from './file/filecopy/filecopy.component'

import { FreqwordsComponent } from './setdict/freqwords/freqwords.component'

import {CalculatorComponent } from './class/calculator/calculator.component'
import { EvaluateExpressionComponent } from './print/evaluate-expression/evaluate-expression.component';

import { IndexComponent } from './index/index.component'
import { FAQComponent} from './faq/faq.component'
import { BonusCalculatorComponent } from './class/bonus-calculator/bonus-calculator.component';
import { PalindromeComponent } from './arrays/palindrome/palindrome.component';
import { HigestmarkscalculatorComponent } from './arrays/higestmarkscalculator/higestmarkscalculator.component';
import { MatixAdditionComponent } from './arrays/matix-addition/matix-addition.component'
import { MatrixMultiplicationComponent } from './arrays/matrix-multiplication/matrix-multiplication.component'
import { BudgetcalculatorComponent } from './arrays/budgetcalculator/budgetcalculator.component'
import { BillingComponent } from './class/billing/billing.component'
import { StudentdiscountComponent } from './class/studentdiscount/studentdiscount.component'
import { StudentdbComponent} from './file/studentdb/studentdb.component'
import { BinarysearchComponent } from './function/binarysearch/binarysearch.component'
import { MergesortComponent } from './function/mergesort/mergesort.component'
import { TaxiFareCalculatorComponent} from './function/taxi-fare-calculator/taxi-fare-calculator.component'
import { LicenseEligibilityComponent} from './function/license-eligibility/license-eligibility.component'
import { DiceThrowComponent } from './arrays/dice-throw/dice-throw.component'
import { TimeInSecondsComponent } from './function/time-in-seconds/time-in-seconds.component'

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'hello-world', component: HelloworldComponent },
  { path: 'print-var', component: PrintwithvarComponent },
  { path: 'print-1-to-100', component: Display1to100Component },
  { path: 'sum-of-digits', component: SumdigitsComponent },
  { path: 'think-of-a-number', component: ThinkOfANumberComponent },
  { path: 'swap-values', component: SwapvaluesComponent },
  { path: 'area-of-circle', component: CircumcricleComponent },
  { path: 'fahren-to-celsius', component: FahrenheitToCelsiusComponent },
  { path: 'compound-interest', component: CompundInterestComponent },
  { path: 'vat-calculator', component: VatCalculatorComponent },
  { path: 'percent-increase', component: PercentIncreaseComponent },
  { path: 'calculate-grade', component: CalcgradeComponent },
  { path: 'day-of-week', component: DayOfWeekComponent },
  { path: 'leap-year', component: LeapYearComponent },
  { path: 'username-pwd', component: UsernamePwdLoginComponent },
  { path: 'decimal-binary', component: DecimalToBinaryComponent },
  { path: 'energy-bill', component: EnergyBillCalculatorComponent },
  { path: 'find-largest', component: LargestComponent },
  { path: 'find-alphabet', component: AlphabetComponent },
  { path: 'odd-or-even', component: OddorevenComponent },
  { path: 'positive-negative-zero', component: PositivenegativeComponent },
  { path: 'find-vowel', component: VowelsComponent },
  { path: 'sumofnumbers', component: SumofnumbersComponent },
  { path: 'sumofnumbersusingclass', component: SumofnumbersusingclassComponent },
  { path: 'count-number-of-lines', component: NumberoflinesComponent },
  { path: 'count-number-of-words', component: CountwordsComponent },
  { path: 'file-copy', component: FilecopyComponent },
  { path: 'count-frequency-words', component: FreqwordsComponent },
  { path: 'average-of-n-numbers', component: AverageComponent },
  { path: 'calculator', component: CalculatorComponent },
  { path: 'total-of-N-numbers', component: ArraysComponent },
  { path: 'multiplication-table', component: MultiplicationTableComponent },
  { path: 'bubble-sort', component: BubbleSortComponent },
  { path: 'evaluate-expression', component: EvaluateExpressionComponent},
  { path: 'index', component: IndexComponent},
  { path: 'faq', component: FAQComponent},
  { path: 'bonus-calculator', component: BonusCalculatorComponent},
  { path: 'palindrome', component: PalindromeComponent},
  { path: 'highest-marks', component: HigestmarkscalculatorComponent},
  { path: 'matrix-addition', component: MatixAdditionComponent},
  { path: 'matrix-multiplication', component: MatrixMultiplicationComponent},
  { path: 'budget-calculator', component: BudgetcalculatorComponent},
  { path: 'discountcalculator', component: StudentdiscountComponent},
  { path: 'billing', component: BillingComponent},
  { path: 'studentdb', component:StudentdbComponent },
  { path: 'binarysearch', component: BinarysearchComponent},
  { path: 'mergesort', component: MergesortComponent},
  { path: 'taxi-fare-calculator', component: TaxiFareCalculatorComponent},
  { path: 'license-eligibility', component: LicenseEligibilityComponent},
  { path: 'dice-throw', component: DiceThrowComponent},
  { path: 'time-in-seconds', component: TimeInSecondsComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
