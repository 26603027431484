import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-largest',
  templateUrl: './largest.component.html',
  styleUrls: ['./largest.component.scss']
})
export class LargestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }
program = `# Find largest of two integers
firstNumber = int(input('Enter the first number'))
secondNumber = int(input('Enter the second number'))

if(firstNumber > secondNumber):
    print(firstNumber,'is bigger than', secondNumber)
else:
    print(firstNumber,'is smaller than', secondNumber)
`
}
