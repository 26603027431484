import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-multiplication-table',
  templateUrl: './multiplication-table.component.html',
  styleUrls: ['./multiplication-table.component.scss']
})
export class MultiplicationTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  forprogram = `# Display multiplication table
userEnteredNumber = int(input("Which multplication table you want?"))

for counter in range(16):
  print(counter,'*',userEnteredNumber,'=', counter * userEnteredNumber) # Python multiplies

`

whileprogram = `# Display multiplication table
userEnteredNumber = int(input("Which multplication table you want?"))

counter = 0
while (counter < 16):
  print(counter,'*',userEnteredNumber,'=', counter * userEnteredNumber) # Python multiplies
  counter = counter + 1

`

}
