import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-decimal-to-binary',
  templateUrl: './decimal-to-binary.component.html',
  styleUrls: ['./decimal-to-binary.component.scss']
})
export class DecimalToBinaryComponent implements OnInit {

  public isHidden: boolean = true;

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `# Convert from decimal to binary
number = int(input("Enter a number"))

print('Method 1: Binary equivalent is', bin(number).replace("0b", ""))     # Python bin function converts to binart with prefix 0b. Replace function removes the prefix

binaryString = ''

while(number > 0):
    binaryString = str(number % 2) + binaryString # Convert reminder to string for concatenation
    number = int(number / 2)                      # Convert to an integer number
    # print(binarystring, number)                 # Uncomment to see the calculations

print('Method 2: Binary equivalent is ', binaryString)
  `

  binaryToDecimalProgram = `# Convert from binary to decimal
number = int(input("Enter a number"))

print('Method 1: Decimal equivalent is', int(number,2))     # Python provides an easy way to convert

decimalNumber = 0

powerOf = 0;
while(number > 0):
    decimalNumber = (number % 10) * 2 ** powerOf  + decimalNumber 
    number = int(number / 10)                                     # Convert to an integer number
    powerOf = powerOf + 1                                         # Increment the power of 10
    # print(decimalNumber, number)                                  # Uncomment to see the calculations
 
print('Method 2: Decimal equivalent is ', decimalNumber)
  `

  public toggleProgram(): void {
    this.isHidden = !this.isHidden;
    brython()
  }

}
