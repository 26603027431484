<p>&nbsp;</p>
<h1 class="bg-white text-dark">Print with variables</h1>

<p>Variables are merely container of some values.</p>

<p>In the below program, the value of 5 is stored in the variable x. Since the variable x is not included in the quotes, python attempts to interpret it. It looks at the variable x and displays the value contained in it.</p>

<app-editor code="{{printWithOneVar}}"> </app-editor>

<p>You can assign any number of characters (called a string) to a variable </p>
<app-editor code="{{printString}}"> </app-editor>
