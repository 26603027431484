import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-studentdiscount',
  templateUrl: './studentdiscount.component.html',
  styleUrls: ['./studentdiscount.component.scss']
})
export class StudentdiscountComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `
class StudentDiscountCalculator:

	def __init__(self, price, discount_calc_method):
		self.price = price
		self.discount_calc_method = discount_calc_method

	def apply_discount(self):
		return self.price - self.price * self.discount_calc_method(self)

def student_discount(item):
	return 0.2

def sale_discount(item):
	return 0.1

price = float(input('Enter item price: '))

isStudent = input('Apply student discount? (Y/N)?')

if isStudent.capitalize() == 'Y':
	disc_calculator = StudentDiscountCalculator(price,student_discount)
else:
	disc_calculator = StudentDiscountCalculator(price,sale_discount)

total = disc_calculator.apply_discount()

print('Please pay', total)

`
}
