import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-mergesort',
  templateUrl: './mergesort.component.html',
  styleUrls: ['./mergesort.component.scss']
})
export class MergesortComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `
def mergeSort(arr):

	if len(arr) > 1:

		# Take the midpoint
		middle = len(arr) // 2

		# Partition the array into 2 arrays (left and right)
		leftArray = arr[:middle]
		rightArray = arr[middle:]
    
		# sort the left array
    	mergeSort(leftArray)

		# sort the right array
    	mergeSort(rightArray)

		i = j = k = 0
		while i < len(leftArray) and j < len(rightArray):
			if leftArray[i] < rightArray[j]:
				arr[k] = leftArray[i]
				i += 1
			else:
				arr[k] = rightArray[j]
				j += 1
			k += 1

		while i < len(leftArray):
			arr[k] = leftArray[i]
			i += 1
			k += 1

		while j < len(rightArray):
			arr[k] = rightArray[j]
			j += 1
			k += 1



# Array to sort.
array = [56, 89, 22, 25, 543, 18]

mergeSort(array)
print("Sorted array is: ", array)
  `
}
