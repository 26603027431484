
<app-programtitle title="Bubble sort"></app-programtitle>


<p> Sorting is one of the most important and useful aspect of our daily lives. There are many ways to sort. Here, we will see the simplest of all called the Bubble Sort  </p>

<app-editor code="{{program}}"> </app-editor>

<p> Let's take 5 numbers and walk through the Bubble sorting algorithm</p>
<p> The numbers are 99, 83, 35, 33 and 5</p>

<p> Pass 1 - Largest number is pushed to the 5th position</p>

<div class="flex-container">
    <div>83</div>
    <div>99</div>
    <div>35</div>  
    <div>22</div>
    <div>5</div>
</div>


<div class="flex-container">
    <div>83</div>
    <div>35</div>
    <div>99</div>  
    <div>22</div>
    <div>5</div>
</div>


<div class="flex-container">
    <div>83</div>
    <div>35</div>
    <div>22</div>  
    <div>99</div>
    <div>5</div>
</div>

<div class="flex-container">
    <div>83</div>
    <div>35</div>
    <div>22</div>  
    <div>5</div>
    <div>99</div>
</div>
<br/>
<p> Pass 2 - Largest numer is pushed to 4th position</p>

<div class="flex-container-pass2">
    <div>35</div>
    <div>83</div>
    <div>22</div>  
    <div>5</div>
    <div>99</div>
</div>

<div class="flex-container-pass2">
    <div>35</div>
    <div>22</div>
    <div>83</div>  
    <div>5</div>
    <div>99</div>
</div>

<div class="flex-container-pass2">
    <div>35</div>
    <div>22</div>
    <div>5</div>  
    <div>83</div>
    <div>99</div>
</div>

<br/>
<p> Pass 3 - Largest number is pushed to 3rd position</p>

<div class="flex-container-pass3">
    <div>22</div>
    <div>35</div>
    <div>5</div>  
    <div>83</div>
    <div>99</div>
</div>

<div class="flex-container-pass3">
    <div>22</div>
    <div>5</div>
    <div>35</div>  
    <div>83</div>
    <div>99</div>
</div>

<br/>
<p> Pass 4 - Largest number is pushed to the 2nd position</p>

<div class="flex-container-pass4">
    <div>5</div>
    <div>22</div>
    <div>35</div>  
    <div>83</div>
    <div>99</div>
</div>