import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-vowels',
  templateUrl: './vowels.component.html',
  styleUrls: ['./vowels.component.scss']
})
export class VowelsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }
program = `
# Find whether a given character is a vowel or consonant
ch = input("Enter a character: ")

if(ch=='A' or ch=='a' or ch=='E' or ch =='e' or ch=='I'
or ch=='i' or ch=='O' or ch=='o' or ch=='U' or ch=='u'):
    print(ch, "is a vowel")
else:
    print(ch, "is a consonant")
  `
}
