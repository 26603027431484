import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-time-in-seconds',
  templateUrl: './time-in-seconds.component.html',
  styleUrls: ['./time-in-seconds.component.scss']
})
export class TimeInSecondsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `
def calculate_time(seconds):
	 hours = int((seconds / 3600))
	 minutes = int(seconds / 60) % 60
	 secs = seconds % 60
	 return hours, minutes, secs

seconds = int(input('Enter number of seconds:  '))
h, m, s = calculate_time(seconds)
print('Hours = ', h, 'Minutes = ',m, 'Seconds = ', s)
`

}
