
<div>&nbsp;</div>
<h2>Python programming frequently asked questions (FAQ) and answers</h2>
<div>&nbsp;</div>

<h3>What are the various applications of Python?</h3>
Below are some of the types of applications where Python is mainly used.
<div>
    <ul>
        <li>Web application development </li>
        <li>Scientific and numeric computational applications (mainly in finance institutions for building various models).</li>
        <li>Enterprise and business applications development.</li>
        <li>Machine Learning and Artificial Intelligence based applications such as Robotics, Computer vision, Text/Image processing  etc.</li>
        <li>Data Science, Data Analysis and Data Visualization.</li>
        <li>Education Programs and Training Programs.</li>
        <li>Automation.</li>
    </ul>
</div>  

<h3>What is scope in Python </h3>
<p>Scope refers to visibility of variables inside the code block where it is created. </p>
<p> <b>Global scope </b> -  Everything defined in this scope are visible from anywhere in the code. </p>
<p> <b>Local scope </b> - Refers to the block of code where a variable is created. </p>
<app-editor code="{{scopeProgram}}"> </app-editor>

<h3>What are lists in Python?</h3>
<p>Represents a collection of objects of different data types that can be accessed by index. List is orderded and modifiable. It provides methods to search, sort, insert, delete items.</p>
<app-editor code="{{listProgram}}"> </app-editor>

<h3>What's the difference between lists and tuples in Python?</h3>

<div>
    <p>
Fundamentally, lists and tuples have different use cases. Lists and Tuples store one or more objects or values in a specific order. <BR>The objects stored in a list or tuple can be of any type including the nothing type defined by the None Keyword.
   </p>
<table class="table table-striped">
    <thead>
        <tr>
            <th>List</th>
            <th>Tuples</th>
        </tr>
    </thead>   
    <tbody>
        <tr>
            <td>Mutable. Values can be changed. </td>
            <td>Immutable. Once created, any changes will create a new instance</td>
        </tr>
        <tr>
            <td>Better suited for insertion, deletion of items</td>
            <td>Better suited only for fast access to the elements</td>
        </tr>
        <tr>
            <td>Cannot be used as a dictionary key as it is mutable.</td>
            <td>Ca be used as a dictionary key as it is immutable.</td>
        </tr>
        <tr>
            <td>Variable length</td>
            <td>Fixed length</td>
        </tr>
    </tbody> 
</table>
<div>&nbsp;</div>
</div>
            

<h3>What is a dictionary in Python?</h3>
<div>
    <p>Dictionary represents a collection of keys and values.</p>
    <p>Dictionary provides a set a <em><strong>mapping</strong></em> from a set of keys to a set of values. Each addition to the dictionary consists of a value and its associated key.</p>
    <p>The concept is borrowed from dictionaries used in real life. When you look up a word (key) in a dictionary, you will get the meaning (value) of it. In much the same way, a real life dictionary can not contain duplicate words, Python dictionaries also can not duplicate keys.</p>

<h3>How do you create a dictionary in Python?</h3>
    <p>Dictionary can be created by provding a set of (inside curly braces) keys and the corresponding values. </p>
    <app-editor code="{{createDictProgram}}"> </app-editor>
</div>

<h3>Can Python dictionary have duplicate keys?</h3>
<div>
    <p>No. Think of it as a real life dictionary used for finding the meaning of words. There will be no duplicate words (keys). When you define duplicate keys, you will not get any errors. But the last one defined overrides all the previous instances.</p>
    <app-editor code="{{duplicateKeyProgram}}"> </app-editor>
</div>

<h3>When do you use dictionary in Python?</h3>
<div>
    <p>When you have a list of unique keys that maps to values cnsider using a dictionary. For E.g, "brand" -&gt; "Apple", "model" -&gt; "iPhone 12"</p>
</div>

<h3>How do you use bubble sort in Python</h3>
<div>
    <p>Please refer to  <a routerLink="/bubble-sort" routerLinkActive="active">Bubble sort in Python</a></p>
</div>