import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-circumcricle',
  templateUrl: './circumcricle.component.html',
  styleUrls: ['./circumcricle.component.scss']
})
export class CircumcricleComponent implements OnInit {

  public isCircumProgramHidden: boolean = true;

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `
# Calculate the radius using the formula PI * Radius squared

# Ask user to input radius (as characters) and convert it to a decimal number 
radius = float(input("Enter radius of Circle: "))
area = 3.14 * radius * radius
print("Area of a circle = ", area)
`

circumprogram = `
# Calcuate the circumference of a circle using the formula 2 * PI * Radius 
radius = float(input("Enter radius of Circle: "))
circumference = 2 * 3.14 * radius 
print("Circumference of a circle = ", circumference)
`

  public toggleCircumProgram(): void {
    this.isCircumProgramHidden = !this.isCircumProgramHidden;
    brython()
  }
}
