import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-bonus-calculator',
  templateUrl: './bonus-calculator.component.html',
  styleUrls: ['./bonus-calculator.component.scss']
})
export class BonusCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `class Base:
  def CalculateBonus(self):
      return self.base * self.bonuspct;

class Employee(Base):
  base = 1000.0
  bonuspct = 0.2;

class Manager(Base):
  base = 1000.0
  bonuspct = 0.5;

class CEO(Base):
  base = 1000.0
  bonuspct = 1;

emp = Employee()
print(emp.CalculateBonus())

emp = Manager()
print(emp.CalculateBonus())

emp = CEO()
print(emp.CalculateBonus())`
}
