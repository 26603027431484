import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-compund-interest',
  templateUrl: './compund-interest.component.html',
  styleUrls: ['./compund-interest.component.scss']
})
export class CompundInterestComponent implements OnInit {

  public siProgramHidden: boolean = true;

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `
# Compound interest calculator

# Ask user to input principal, number of years and interest .
principal = float(input('Enter principal'))
interest = float(input('Enter rate of interest'))
years = float(input('Enter years'))

# Apply the formula to find celsius
ci = principal * (1 + (interest/100)) ** years

print('Compound interest', ci)
    `
  
    siProgram = `
# Simple interest calculator

# Ask user to input principal, number of years and interest .
principal = float(input('Enter principal'))
interest = float(input('Enter rate of interest'))
years = float(input('Enter years'))

# Apply the formula to find celsius
si = (principal * interest * years)/100

print('Simple interest', si)
    
  `

  public toggleProgram(): void {
    this.siProgramHidden = !this.siProgramHidden;
    brython()
  }
  
}
