import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-binarysearch',
  templateUrl: './binarysearch.component.html',
  styleUrls: ['./binarysearch.component.scss']
})
export class BinarysearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `
def binarySearch (arr, l, r, x):
	if r >= l:
		mid = l + (r - l) 
		
    	if arr[mid] == x:
			return mid
		elif arr[mid] > x:
			return binarySearch(arr, l, mid-1, x)
		else:
			return binarySearch(arr, mid + 1, r, x)
	else:
		return -1

arr = [ 10, 20, 30, 40, 50 ]
x = 50

result = binarySearch(arr, 0, len(arr)-1, x)

if result != -1:
	print ("Element is present in the array at index % d" % result)
else:
	print ("Element is not present in array")
`
}
