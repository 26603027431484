import { Component, Input, OnInit } from '@angular/core';
import 'brace';
import 'brace/mode/Python';
import { Renderer2 } from '@angular/core';
import { faRunning } from '@fortawesome/free-solid-svg-icons';

import 'brace';
import 'brace/mode/Python';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  
  @Input() code: string=''; // Holds the code

  @Input() isEditorHidden: boolean = false;
  
  isHidden:boolean=false;
  isOutputEditorHidden:boolean=true;
  options:any = {maxLines: 1000, printMargin: false};
  
  static idCounter: number = 0
  
  id : string = 'i';
  txtAreaId: string = "txt";
  aceditorid: string = "aceditorid";

  faRunning = faRunning;

  constructor(private renderer2: Renderer2) { 
    EditorComponent.idCounter = EditorComponent.idCounter + 1
    this.id = this.id + String(EditorComponent.idCounter);
    this.txtAreaId = this.txtAreaId + String(EditorComponent.idCounter);
    this.aceditorid = this.aceditorid + String(EditorComponent.idCounter);
  }

  ngOnInit(): void {
  
  //   const s = this.renderer2.createElement('script');
  //   s.type = 'text/python3';
      
  //   s.text = `
  //   import sys
  //   from browser import document as doc, window
  //   from browser import html
  //   from browser import document

  //   txtareaid = ''

  //   class cOutput:
  //     encoding = 'utf-8'
  //     output = ''
      
  //     def __init__(self):
  //         self.buf = ''

  //     def write(self, data):
  //         self.buf = str(data)
       
  //         document[self.output] <= data
  //         self.buf = ''

  //     def flush(self):
  //         self.buf = ''
        
  //     def __len__(self):
  //         return len(self.buf)

  //   def execute(args):
  //     element = args.target
  //     txtareaid = "txt" + element.id[1:]            # Pay attention to the prefix used in the file twice
  //     aceditorid = "aceditorid" + element.id[1:]    # Pay attention to the prefix used in the file twice
  //     src = document[aceditorid].text
  //     ns = {'__name__':'__main__'}
  //     exec(src, ns)
  //     cOut = cOutput()
  //     cOut.output = txtareaid
  //     sys.stdout = cOut
      
  //     document[txtareaid].hidden = False;

 
  //   for button in document.select("button"):
  //     print("button",button.id)
  //     button.bind("click", execute)
  //  `;

  //  console.log("Inside editor constructor"); 
  //   this.renderer2.appendChild(document.body, s);
  }

  onChange(code:String) {
    console.log("new code", code);
  }

  public RunbuttonClicked() {
    this.isOutputEditorHidden = false;
  }
}
