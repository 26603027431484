import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-calcgrade',
  templateUrl: './calcgrade.component.html',
  styleUrls: ['./calcgrade.component.scss']
})
export class CalcgradeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `# Calculate grade based on average marks in 5 subjects

englishMark = int(input("Enter your mark in English"))
mathsMark = int(input("Enter your mark in Maths"))
physicsMark = int(input("Enter your mark in Physics"))
chemistryMark = int(input("Enter your mark in Chemistry"))
biologyMark = int(input("Enter your mark in Biology"))

totalMarks = englishMark + mathsMark + physicsMark + chemistryMark + biologyMark
average = totalMarks / 5

if average >= 91 and average <= 100:
    print("Your Grade is A1")
elif average >= 81 and average < 91:
    print("Your Grade is A2")
elif average >= 71 and average < 81:
    print("Your Grade is B1")
elif average >= 61 and average < 71:
    print("Your Grade is B2")
elif average >= 51 and average < 61:
    print("Your Grade is C1")
elif average >= 41 and average < 51:
    print("Your Grade is C2")
elif average >= 33 and average < 41:
    print("Your Grade is D")
else:
    print("Fail")  
`
}
