<app-programtitle title="Decimal to binary"></app-programtitle>
<p> Write a program to convert a decimal number to binary. </p>
<p> There are many ways to convert a decimal number to a binary number. Python itself provides a function to convert. </p>
<app-editor code="{{program}}"> </app-editor>

<app-exercisetitle title="Exercise"> </app-exercisetitle>
<p> Write a program to convert a binary number to decimal. </p>

<button class="btn btn-outline-primary" (click) = "toggleProgram()" id ="NoBrython">Show answer</button>
<br/>
<br/>
<app-editor *ngIf = "!isHidden" code="{{binaryToDecimalProgram}}"> </app-editor>