<app-programtitle title="Calculate grade based on marks obtained"></app-programtitle>
<p> Write a program to find the grade given marks in 5 subjects. The grade bands are 
    91-100 - A1
    81-90  - A2
    71-80  - B1
    61-70  - B2
    51-60  - C1
    41-50  - C2
    31-40  - D
    Below 31 - fail
</p>

<app-editor code="{{program}}"> </app-editor>

