import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-numberoflines',
  templateUrl: './numberoflines.component.html',
  styleUrls: ['./numberoflines.component.scss']
})
export class NumberoflinesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `
fname = input("Enter file name: ")
num_lines = 0
with open(fname, 'r') as f:
    for line in f:
        num_lines += 1
print("Number of lines:")
print(num_lines)
  `
}
