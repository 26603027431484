<h1>List of Python practice exercises</h1>

<li>
    <a routerLink="/hello-world" routerLinkActive="active" >Hello world!</a>
</li>
<li>
    <a routerLink="/evaluate-expression" routerLinkActive="active">Print with expressions</a>
</li>
<li>
    <a routerLink="/print-var" routerLinkActive="active">Print with variables</a>
</li>

<li>
    <a routerLink="/think-of-a-number" routerLinkActive="active"  >Think of a number</a>
</li>
<li>
    <a routerLink="/swap-values" routerLinkActive="active"   >Swap two numbers</a>
</li>
<li>
    <a routerLink="/area-of-circle" routerLinkActive="active"   >Calculate area of a cirlce</a>
</li>

<li>
    <a routerLink="/find-largest" routerLinkActive="active"   >Find largest of two numbers</a>
</li>
<li>
    <a routerLink="/odd-or-even" routerLinkActive="active"   >Find odd or even number</a>
</li>
<li>
    <a routerLink="/find-alphabet" routerLinkActive="active"   >Find a characracter alphabet</a>
</li>
<li>
    <a routerLink="/positive-negative-zero" routerLinkActive="active"   >Check if a number is +ve or -ve or zero</a>
</li>
<li>
    <a routerLink="/find-vowel" routerLinkActive="active"   >Find if a characracter is vowel</a>
</li>
<li>
    <a routerLink="/calculate-grade" routerLinkActive="active"   >Calculate grade</a>
</li>

<li>
    <a routerLink="/print-1-to-100" routerLinkActive="active"   >Display from 1 to 100</a>
</li>
<li>
    <a routerLink="/multiplication-table" routerLinkActive="active"  > Multiplication table </a>
</li>
<!-- <li>
    <a href="#">Find the factors of a number</a>
</li>
<li>
    <a href="#">Display from 1 to 100 all even numbers only</a>
</li> -->
<li>
    <a routerLink="/sum-of-digits" routerLinkActive="active"   >Find sum of digits</a>
</li>
<li>
    <a routerLink="/bubble-sort" routerLinkActive="active"   >Bubble sort</a>
</li>

<li>
    <a routerLink="/total-of-N-numbers" routerLinkActive="active"  >Find total of n numbers</a>
</li>

<li>
    <a routerLink="/count-frequency-words" routerLinkActive="active"   >Count frequency of words</a>
</li>

<li>
    <a routerLink="/file-copy" routerLinkActive="active">Copy a file</a>
</li>
<li>
    <a routerLink="/count-number-of-lines" routerLinkActive="active">Count number of lines</a>
</li>
<li>
    <a routerLink="/count-number-of-words" routerLinkActive="active">Count number of words</a>
</li>

<li>
    <a routerLink="/average-of-n-numbers" routerLinkActive="active">Average of n numbers</a>
</li>

<li>
    <a routerLink="/calculator" routerLinkActive="active" >Simple calculator</a>
</li>
