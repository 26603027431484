import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-palindrome',
  templateUrl: './palindrome.component.html',
  styleUrls: ['./palindrome.component.scss']
})
export class PalindromeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `
def reverse(s):
    return s[::-1]

str = input('Enter a word or a setence')

if str == reverse(str):
    print('Its a palindrome')
else:
    print('Not a palindrome')`
}
