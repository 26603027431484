<app-programtitle title="Calculate compound interest"></app-programtitle>

<p> Write a program calculate compound interest using the formula  CI = P(1 + r/n)^nt. The symbol (^) means 'power of' </p>

<app-editor code="{{program}}"> </app-editor>


<app-exercisetitle title="Exercise"> </app-exercisetitle>
<p> Write a program to calculate simple interest SI = (PNR)/100 </p>

<button class="btn btn-outline-primary" (click) = "toggleProgram()" id ="NoBrython">Show answer</button>
<br/>
<br/>
<app-editor *ngIf = "!siProgramHidden" code="{{siProgram}}"> </app-editor>

