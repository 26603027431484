import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-arrays',
  templateUrl: './arrays.component.html',
  styleUrls: ['./arrays.component.scss']
})
export class ArraysComponent implements OnInit {

  isAvgProgramHidden = true;
  totalofNnumbersProgram:string = `# Find total of n given integers

totalNumbers = int(input('How many numbers are you going to enter?'))
numbers = [] # Store all the user entered numbers in an array
total = 0
  
for i in range(totalNumbers):   
  number = int(input('Enter number ', i))
  numbers.append(number)
  total = total + number
   
print('The numbers you entered are')
for userEnteredNumber in numbers:
  print(userEnteredNumber)
  
print('The total of these numbers are ', total)`;


averageOfNnumbersProgram:string = `# Find total of n given integers

totalNumbers = int(input('How many numbers are you going to enter?'))
numbers = [] # Store all the user entered numbers in an array
total = 0
  
for i in range(totalNumbers):   
  number = int(input('Enter number ', i))
  numbers.append(number)
  total = total + number
   
print('The numbers you entered are')
for userEnteredNumber in numbers:
  print(userEnteredNumber)

average = total / len(numbers)
print('The average of these numbers are ', average)`;
  
constructor() { }

  ngOnInit(): void {
    brython();
  }

  public toggleAvgProgram(): void {
    this.isAvgProgramHidden = !this.isAvgProgramHidden;
    brython()
  }
}
