

<app-programtitle title="Swap two values"></app-programtitle>

<p> Write a program to swap values of two variables. </p>

<p>The problem in swapping is that we can't assign the values to each other. For e.g, if a = 10 and b = 20 and if we write a = b and b = a, it will not work.
The reason is when assign a = b the value of b is copied into a. It makes a = 10 and b = 10. Now when the statement b = a is executed, it copies the value of a into b which is 20 in this case. So both of them will be 20 </p>

<p> To avoid this, first we copy the value of one of the variables into another variable (say C). Now we assign the value of b to a (a = b). Finally, we assign the value of C (which is the orginal value of a) to b (b = c).</p>

<app-editor code="{{program}}"> </app-editor>