import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-sumofnumbersusingclass',
  templateUrl: './sumofnumbersusingclass.component.html',
  styleUrls: ['./sumofnumbersusingclass.component.scss']
})
export class SumofnumbersusingclassComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }
program = ``
}
