<app-programtitle title="Calculate Area of a circle"></app-programtitle>

<p> Calculate the area of the circle using the forumla : 3.14 * r * r (PI * r * r). The unknown here is the radius (r). We ask the user to input the radius and apply it in the formula.</p>

<p>The input function returns a user's input as a string of characracters. The float function converts user's input into a floating point (decimal) number.</p>
<app-editor code="{{program}}"> </app-editor>

<app-exercisetitle title="Exercise"> </app-exercisetitle>


<p> Write a program to calculate the cirucumference of a circle using the formula 2 * 3.14 * r</p>

<button class="btn btn-outline-primary" (click) = "toggleCircumProgram()" id ="NoBrython">Show answer</button>
<br/>
<br/>
<app-editor *ngIf = "!isCircumProgramHidden" code="{{circumprogram}}"> </app-editor>