import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-leap-year',
  templateUrl: './leap-year.component.html',
  styleUrls: ['./leap-year.component.scss']
})
export class LeapYearComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `# Find leap year or not
year = int(input('Enter a year'))

if(year % 4 == 0):
    print('The year', year,'is a leap year.')
else:
    print('The year', year,'is not a leap year.')
      `

}
