import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-display1to100',
  templateUrl: './display1to100.component.html',
  styleUrls: ['./display1to100.component.scss']
})
export class Display1to100Component implements OnInit {

  isEvenProgramHidden:boolean = true;
  isUserEnteredEvenProgramHidden:boolean = true;

  constructor() {   
  }

  ngOnInit(): void {
    brython()
  }

  public toggleEvenProgram(): void {
    this.isEvenProgramHidden = !this.isEvenProgramHidden;
    brython()
  }

  public toggleUserEnteredEvenProgram(): void {
    this.isUserEnteredEvenProgramHidden = !this.isUserEnteredEvenProgramHidden;
    brython()
  }

  display1to100:string = `# Display all the numbers upto 100
for counter in range(100):
	print(counter)`;

  display1toUserEnteredNumber:string = `# Display all the numbers upto a given number by user
lastNumber = int(input('Enter the last number')) 

for counter in range(lastNumber):
	  print(counter)`;

    displayOddNumbers:string = `# Display all ODD numbers from 1 to 100

for counter in range(1, 100, 2): # Start from 1. Go upto 100. Jump in 2's
    print(counter)`;
    
    displayEvenNumbers:string = `# Display all Even numbers from 0 to 100

for counter in range(0, 100, 2): # # Start from 0. Go upto 100. Jump in 2's
    print(counter)`;

    displayEvenNumbersUptoUserEntered =  `# Display all Even numbers from 0 to a user entered number
lastNumber = int(input('Enter the last number')) 
    
for counter in range(0, lastNumber, 2): # Start from 0 jump in 2's
    print(counter)`;

}
