import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-filecopy',
  templateUrl: './filecopy.component.html',
  styleUrls: ['./filecopy.component.scss']
})
export class FilecopyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `
with open("test.txt") as f:
  with open("out.txt", "w") as f1:
      for line in f:
          f1.write(line)
  `
}
