import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `
class Cart:

  def __init__(self):
      self.items = []

  def add_item(self, product):
      self.items.append(product)

class Product:
	
	def __init__(self, name, qty, price):
		self.name = name
		self.qty = qty
		self.price = price
		self.total = price * qty

cart = Cart()

print('Enter products details. Type q as product name to finish.')
print('Item', 'Quantity', 'Price', 'Total')

while(1):	
	name = input('Enter product name:')
	if(name == 'q'):
		break
	qty = int(input('Enter quantity: '))
	price = float(input('Enter price: '))
	cart.add_item(Product(name,qty,price))

print('Shopping cart has ', len(cart.items), ' items.')
print('Product Name', 'Quantity','Price','Total')

total = 0
for item in cart.items:
	print(item.name, item.qty,item.price, item.total)
	total += item.total

print('Total to pay', total)
`

}
