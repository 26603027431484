import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-fahrenheit-to-celsius',
  templateUrl: './fahrenheit-to-celsius.component.html',
  styleUrls: ['./fahrenheit-to-celsius.component.scss']
})
export class FahrenheitToCelsiusComponent implements OnInit {

  public ctofProgramHidden: boolean = true;

  constructor() { }

  ngOnInit(): void {
    brython()
  }
  program = `
# Fahrenheit to celsius conversion

# Ask user to input fahrenheit (as characters) and convert it to a decimal number 
fahrenheit = float(input('Enter Fahrenheit'))

# Apply the formula to find celsius
celsius = (5/9) * (fahrenheit - 32)

print('Celsius equivalent of Fahrenheit',fahrenheit,'is',celsius)
  `

  ctofProgram = `
# Celsius to Fahrenheit to conversion

# Ask user to input celsius.
celsius = float(input('Enter Celsius'))

# Apply the formula to find celsius
fahrenheit = (9/5 * celsius) + 32

print('Fahrenheit equivalent of Celsius',celsius,'is',fahrenheit)
`

  public toggleProgram(): void {
    this.ctofProgramHidden = !this.ctofProgramHidden;
    brython()
  }
}
