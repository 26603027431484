import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-oddoreven',
  templateUrl: './oddoreven.component.html',
  styleUrls: ['./oddoreven.component.scss']
})
export class OddorevenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `# Find odd or even
userNumber = int(input('Enter a number'))

if(userNumber % 2 == 0):
    print('The number', userNumber,'is even')
else:
    print('The number', userNumber,'is odd')
    `
}
