import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython();
  }
  
  scopeProgram = `burgerPrice = 5 	 # defined in global scope (main program)

def macdonalds():
      burgerPrice = 10   # defined in local scope (in this case function)
      print(burgerPrice)

print(burgerPrice) 	 # prints 5
macdonalds() 	       # prints 10
print(burgerPrice) 	 # prints 5 again`

  listProgram =  `list = ["car","train","plane","rocket"]
print(list)
print(len(list)) # prints the number of items in the list`;

  createDictProgram =  `dict = {
    "model": "iPhone 13",
    "year": 2021,
    "maker": "Apple"
  }
print(dict)`;

  duplicateKeyProgram = `dict = {
    "country":"USA",
    "country":"UK"
    }
    
print(dict['country']) # prints UK`;

}
