import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-countwords',
  templateUrl: './countwords.component.html',
  styleUrls: ['./countwords.component.scss']
})
export class CountwordsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program = `
fname = input("Enter file name: ")
word=input("Enter word to be searched:")

k = 0
  
with open(fname, 'r') as f:
    for line in f:
        words = line.split()
        for i in words:
            if(i==word):
                k=k+1
print("Occurrences of the word:",k)

`

}
