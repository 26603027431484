import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-license-eligibility',
  templateUrl: './license-eligibility.component.html',
  styleUrls: ['./license-eligibility.component.scss']
})
export class LicenseEligibilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

program = `
def is_elgible_for_license(age):
	if age < 17:
		print('You are not eligible for a driving license')
		return
	print('You are eligible for a driving license.')

age = int(input('Enter your age: '))
is_elgible_for_license(age)
`

}
