import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-think-of-a-number',
  templateUrl: './think-of-a-number.component.html',
  styleUrls: ['./think-of-a-number.component.scss']
})
export class ThinkOfANumberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  program1 = `# Instructions
print('Think of any number')
print('Add 5 to it')
print ('Multiply by 3')
print('Subtract 15')
print('Divide by the the number you thought of')
print('Add 7')

input('Press Enter. I will read your mind and tell you the number you are thinking of.')
print('I read your mind. The number you are thinking of is 10.') # Answer will always be 10
`

program2 = `# Instructions
print('Think of any number between 1 and 8')
print('Multiply by 2')
print ('Multiply by 5')
print('Subtract 5')
print('Add 7')

input('Press Enter. I will read your mind and tell you the number you are thinking of.')
print('I read your mind. The number you are thinking of is 2.') # Answer will always be 2
`

program3 = `# Instructions
print('Think of any number')
print('Double the number')
print ('Add 9')
print('Subtract 3')
print('Divide by 2')
print('Subtract the answer from the number you thought of')

input('Press Enter. I will read your mind and tell you the number you are thinking of.')
print('I read your mind. The number you are thinking of is 3.') # Answer will always be 3
`

}
