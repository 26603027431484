<app-programtitle title="Fahrenheit to celisus converter"></app-programtitle>

<p> Write a program to convert fahrenheit to celisus using the formula C = (5/9) * (F - 32) </p>

<app-editor code="{{program}}"> </app-editor>


<app-exercisetitle title="Exercise"> </app-exercisetitle>
<p> Write a program to convert celsius to farhenheit using the formula F = (5/9 * C) + 32 </p>

<button class="btn btn-outline-primary" (click) = "toggleProgram()" id ="NoBrython">Show answer</button>
<br/>
<br/>
<app-editor *ngIf = "!ctofProgramHidden" code="{{ctofProgram}}"> </app-editor>

