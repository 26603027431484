import { Component, OnInit } from '@angular/core';

declare function brython(): any;

@Component({
  selector: 'app-helloworld',
  templateUrl: './helloworld.component.html',
  styleUrls: ['./helloworld.component.scss']
})
export class HelloworldComponent implements OnInit {

  constructor( ) {}

  ngOnInit(): void {
    brython()
  }

  helloWorldProgram:string = "print('Hello world')";

  quotesProgram:string = `print('Hello! Welcome to my first awesome python program') 
print(\"My name John Appleseed\")`;
  
  listProgram:string = `print('apple','orrange','mangoes')
print('maths','physics','chemistry')
print('python','is','awesome')`



}
