import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-exercisetitle',
  templateUrl: './exercisetitle.component.html',
  styleUrls: ['./exercisetitle.component.scss']
})
export class ExercisetitleComponent implements OnInit {

  @Input() title: string=''; 

  constructor() { }

  ngOnInit(): void {
  }

}
