import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-percent-increase',
  templateUrl: './percent-increase.component.html',
  styleUrls: ['./percent-increase.component.scss']
})
export class PercentIncreaseComponent implements OnInit {

  public isHidden: boolean = true;

  constructor() { }

  ngOnInit(): void {
    brython()
  }
program = `# Calculate percentage increase
initialValue = float(input('Enter initial value'))
finalValue = float(input('Enter final value'))
percentIncrease = (finalValue - initialValue)/initialValue * 100
print('Percentage increase', percentIncrease,'%')
`

exerciseProgram = `# Calculate percentage decrease
initialValue = float(input('Enter initial value'))
finalValue = float(input('Enter final value'))
percentDecrease = (finalValue - initialValue)/initialValue * 100
print('Percentage decrease', percentDecrease,'%')
`

public toggleProgram(): void {
  this.isHidden = !this.isHidden;
  brython()
}

}
