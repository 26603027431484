import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-average',
  templateUrl: './average.component.html',
  styleUrls: ['./average.component.scss']
})
export class AverageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }
program = `
def avg(arr, total):
    sum = 0
    for i in range(total):
        sum = sum+arr[i]
    avg = sum/total
    return avg

totalNums = int(input("How many number you you want to find the average for? "))

nums = []
for i in range(totalNums):
    nums.append(int(input("Enter number " + str(i+1))))

result = avg(nums, totalNums)
print("Average = ", result)
`

}
