<app-programtitle title="Find total of given 'n' integers"></app-programtitle>
<p> Write a program to find the total of given 'n' numbers </p>
<app-editor code="{{totalofNnumbersProgram}}"> </app-editor>

<app-exercisetitle title="Exercise"> </app-exercisetitle>
<p> Write a program to find the average of given 'n' integers. Hint: Use len(numbers) to return the number of elemetns in an array. </p>

<button class="btn btn-outline-primary" (click) = "toggleAvgProgram()" id ="NoBrython">Show answer</button>
<br/>
<br/>
<app-editor *ngIf = "!isAvgProgramHidden" code="{{averageOfNnumbersProgram}}"> </app-editor>