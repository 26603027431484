import { Component, OnInit } from '@angular/core';
declare function brython(): any;

@Component({
  selector: 'app-evaluate-expression',
  templateUrl: './evaluate-expression.component.html',
  styleUrls: ['./evaluate-expression.component.scss']
})
export class EvaluateExpressionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    brython()
  }

  displayNumberProgram = `print(1) # Python interprets as number one
print('1') # Python does not interpret this and just displays whatever is given within the quotes
print(2+3) # Python interprets it as an expression and evaluates it and prints the answer
print('3*3') # Python does not interpret it and just displays whatever is given within the quotes`

orderOfPrecendenceProgram = `print(10 * (4 + 1)) # Displays 50 as brackets will be given precendence
print(10 + 4 * 1 ) # Displays 14 as multiplication has higher precendence than addition
print(50/2+3*4) # Displayes 37. Evaluates division first (and not multiplication) as it appears to the left first
print(10 * 10 ** 2) # Displays 1000 as ** (to the power of) operator has prcedence over multiplication`

}
